body {
  margin: 0;
  font-family: lato, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #202020;
}
h2 {
  font-size: 3.3rem;
  margin-bottom: 4rem;
  display: block;
  text-align: center;
  /* margin: 0 auto; */
  position: relative;
  color: #292828;
}

h2::after {
  content: "";
  position: absolute;
  top: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  width: 8rem;
  height: 3px;
  background-color: #33343033;
}

h3 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
}

p {
  font-size: 1.7rem;
  letter-spacing: 0.25px;
  color: #303030;
  display: block;
  margin-bottom: 2rem;
}

a {
  text-decoration: none;
  font-weight: 600;
  color: rgb(74, 74, 201);
}
