*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}
:root {
  --primary-color: #9e9e9e;
  --primary-color-light: #d3d3d3;
  --secondary-color: #6d6d6a;
  --secondary-color-hover: #7d7d7d;
  --secondary-color-dark-hover: #545454;
  --secondary-color-dark: #434340;
  box-sizing: border-box;
  font-size: 62.5%;
}
.body {
  background-color: rgba(252, 249, 242);
}
