.code-snippet__container {
  border-radius: 3px;
  box-shadow: 0px 0px 5px rgb(114, 114, 114);
  margin: 3rem auto;
  padding: 2rem;
  background-color: rgb(39, 40, 34);
}

.code-snippet {
  font-size: 1.8rem !important;
  line-height: 2.2rem !important;
}
