.button-grey {
  background-color: var(--secondary-color);
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 3px;
  position: relative;
  transition: all 0.2s;
  cursor: pointer;
}
.button-grey::after,
.button-grey::before {
  content: "";
  background-color: var(--primary-color);
  width: 2rem;
  height: 3px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 1px;
}
.button-grey::after {
  transform: translate(-50%, -50%) rotate(90deg);
}
.button-grey:hover {
  background-color: var(--secondary-color-hover);
}

.boxShadow,
.boxShadow--padding {
  background-color: rgb(39, 40, 34);
  border-radius: 3px;
  box-shadow: 0px 0px 5px rgb(114, 114, 114);
  margin: 3rem auto;
}

.boxShadow--padding {
  padding: 2rem 0;
}
